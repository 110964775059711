.nodes-embed{

}

//node detail
/** node detail */
.nodedetail {
  & tr td.key {
    width: 200px;
    vertical-align: top;

    &.namespace {
      padding-right: 20px;
    }
  }

  & .key {
    text-align: right;
    font-weight: bold;
    color: #555;
  }

  & tr .value {
    vertical-align: top;
    max-width: 800px;
  }

  &.detailpopup {
    max-height: 500px;
    max-width: 600px;
    overflow-y: auto;
  }

  &.embed {
    & tr .value {
      max-width: 400px;
    }
  }
}

.node_entry {
  .hostname,
  .nodedesc,
  .nodeident,
  .nodetags,
  .username {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .nodetags {
    max-width: 20em;
    .label{
      a{
        color:white;
      }
    }
  }
}

.node_entry.server .nodedesc:before {
  color: #f66;
  content: " server ";
  padding-left: 18px;
}

.nodedetail.server .nodedesc:before {
  color: #f66;
  content: " server " "\2022" " ";
  padding-left: 18px;
}
