.wfnodestate{
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 5px 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.wfnodestep {
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin: 4px 0 0 20px;
  padding: 5px 3px;
  .closed {
    display: none;
  }
  .stepident{

  }
}

.wfnodestep.open {
  .closed {
    display: block;
  }
}
