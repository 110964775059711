@mixin social-buttons-color ($color){

  border-color: $color;
  color: $color;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $color;
    border-color: $color;
    color: $white-color;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    background-color: $transparent-bg;
    border-color: $color;
  }

  &.btn-fill {
    color: $white-color;
    background-color: $color;
    opacity: 0.8;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle{
        background-color: $color;
        border-color: $color;
        color: $white-color;
        opacity: 1;
    }

  }

  &.btn-simple {

    color: $color;
    opacity: 0.8;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle{
        background-color: $transparent-bg;
        color: $color;
        opacity: 1;
    i{
        color: $color;
        opacity: 1;
    }

    }
  }

}
@mixin social-label-color ($color){
    background-color: $color;
}
@mixin social-icon-color ($color){
    color: $color;
}
