$popover-wide-max-width: 500px;
//popover variation
.popover {
  z-index: 2000;
  &.popover-wide {
    max-width: $popover-wide-max-width;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(194,194,194,1);
    box-shadow: 0px 0px 10px 1px rgba(194,194,194,1);   
  }
}
