@import '_glyphicons';
.auto-caret-open {
  @extend .glyphicon;
  @extend .glyphicon-menu-down;
}

.accordion-toggle.collapsed .auto-caret,
.auto-caret {
  @extend .glyphicon;
  @extend .glyphicon-menu-right;
}

.accordion-toggle,
.auto-caret-container {
  .auto-caret,
  .auto-caret-left {
    @extend .glyphicon;
    @extend .glyphicon-menu-down;
  }
}

.active {
  > .auto-caret-left,
  > .auto-caret {
    @extend .glyphicon;
    @extend .glyphicon-menu-down;
  }

  > * > .auto-caret-left,
  > * > .auto-caret {
    @extend .glyphicon;
    @extend .glyphicon-menu-down;
  }
}

.auto-caret-left {
  @extend .glyphicon;
  @extend .glyphicon-menu-left;
}
