//workflow editor, option editor, autohilite:
btn.autohilite:hover,
span.autohilite:hover {
  background: $info-color;
  background: $light-gray;
}

span.autoedit {
  padding: 3px;
  cursor: pointer;
}

span.autoedit:hover {
  padding: 2px;
  border: 1px dotted $info-color;
}
/**
   * single step or option edit box
   */
.optEditForm,
.wfitemEditForm {
  border: 2px solid #e5e5e5;
  border-bottom-radius: 5px;
  border-top-radius: 5px;
  padding: 10px;

  &.container {
    background: white;
  }
}
//option values in code sections
.optvalue {
  color: $primary-color;

  &.optextra {
    border-bottom: 1px dashed $primary-color;
  }
}

.optkey {
  color: $primary-color;
}
