/** plugin configuration styles */
.configvalue {
//    color: #080;
}

.configpair {
  display: block;
  //white-space: nowrap;
  word-wrap: break-word;
    // padding: 2px;
    // background-color: #eee;
    // border-radius: 3px;
    // margin-right: 2px;
}
