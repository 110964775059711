.card-expandable{
  .card-header{
    padding-bottom: 20px;
  }
}
.card-expandable.card-expandable-open{
  .card-header{
    padding-bottom: 0;
  }

}
