//events list
.table.events-table {
  table-layout: fixed;

  td.eventargs {
    width: auto;
    overflow: auto;
    text-overflow: ellipsis;
  }

  td.eventicon {
    width: 24px;
  }

  td.eventtitle {
    width: auto;
  }

  td.eventtitle.adhoc {
    width: auto;
  }

  td.date {
    width: 20%;
  }

  td.user {
    width: 10%;
  }

  td.nodecount {
    white-space: nowrap;
    text-align: right;
    width: 5%;
  }

  &.events-table-embed {
    tr.adhoc {
      td.eventtitle {
        width: auto;
      }

      td.eventargs {
        width: 0;
      }
    }

    td.user {
      //nodecount is not shown
      width: 15%;
    }
  }
}

.table.events-table td.eventtitle.adhoc {
  font-style: italic;
  // color: #888;
}
