@import "variables";
.table{

  border-collapse: inherit;

  thead,
  tbody,
  tfoot{
    tr > th,
    tr > td{
      border-top: 1px solid $table-line-color;
    }
    tr.success{
      th,td{
        background-color: lighten($bg-success, 10%);
      }
      &:hover{
        th, td{
          background-color: lighten($bg-success, 5%);
        }
      }
    }

    tr.info{
      th,td{
        background-color: lighten($bg-info, 10%);
      }
      &:hover{
        th, td{
          background-color: lighten($bg-info, 5%);
        }
      }
    }

    tr.warning{
      th,td{
        background-color: lighten($bg-warning, 10%);
      }
      &:hover{
        th, td{
          background-color: lighten($bg-warning, 5%);
        }
      }
    }

    tr.danger{
      th,td{
        background-color: lighten($bg-danger, 10%);
      }
      &:hover{
        th, td{
          background-color: lighten($bg-danger, 5%);
        }
      }
    }
  }
  > thead > tr > th{
    border-bottom-width: 0;
    font-size: $font-size-h5;
    font-weight: $font-weight-light;
  }

  .radio,
  .checkbox{
    margin-top: 0;
    margin-bottom: 22px;
    padding: 0;
    width: 15px;
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td{
    padding: 12px 11px;
    vertical-align: middle;
  }

  .th-description{
    max-width: 150px;
  }
  .td-price{
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    text-align: right;
  }
  .td-total{
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn{

    &.btn-sm,
    &.btn-xs{
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  > tbody > tr{
    position: relative;
  }

  .has-switch{
    top: 9px;
    margin-top: -12px;
  }
}

.table-striped{
  tbody > tr:nth-of-type(2n+1) {
    background-color: $gray-input-bg;
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td{
    padding: 15px 8px;
  }
}
.table-shopping{
  > thead > tr > th{
    color: $muted_color;
    font-size: 1.1em;
    font-weight: $font-weight-light;
  }
  > tbody > tr > td{
    font-size: $font-paragraph;
    padding: 30px 5px;
    b{
      display: block;
      margin-bottom: 5px;
    }
  }
  .td-number,
  .td-price,
  .td-total{
    font-size: 1.2em;
    min-width: 130px;
    text-align: right;
    padding-right: 20px;

    small{
      margin-right: 3px;
    }
  }
  .td-product{
    min-width: 170px;
    padding-left: 30px;
    strong{
      color: $default-states-color;
      font-size: 1.2em;
      font-weight: $font-weight-bold;
    }
  }
  .td-number,
  .td-total{
    color: $default-states-color;
    font-weight: $font-weight-bold;
  }
  .td-quantity{

    .btn-group{
      margin-left: 10px;
    }
  }
  .img-container{
    border-radius: $border-radius-large;
    display: block;
    height: 100px;
    overflow: hidden;
    width: 100px;
    margin-left: 10px;

    img{
      width: 100%;
    }
  }
  .tr-actions >td{
    border-top: 0;
  }


}

.table-icons{
  display: inline-block;
  min-width: 110px;
}

// Element UI override

.el-table td, .el-table th.is-leaf{
  border: none;
}
.el-table tr{
  z-index: 10;
}
.el-table {
  .el-checkbox__inner:hover,
  .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: $default-color;
    border-color: $default-color;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: $default-color;
  }
}

.el-table .el-table__footer .cell{
  font-weight: bold;
  font-size: $font-size-medium;
}
.el-table  {
  tbody tr td:first-child,
  thead tr th:first-child{
    padding-left: $padding-base-horizontal;
  }
}

.card-plain .el-table {
  background-color: transparent;
  tr {
    background-color: transparent;
  }
}
