.radio{
  .help-block{
    margin-left:10px;
  }
}

//style text like a form label
.text-form-label {
    margin-bottom: 5px;
    font-weight: bold;
}