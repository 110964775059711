/*           Labels & Progress-bar              */
.label{
   @include label-style();
}
.label-icon{
    padding: 0.4em 0.55em;
    i{
       font-size: 0.8em;
       line-height: 1;
    }
}
.label-default{
    background-color: $font-color;
}
.label-primary{
    background-color: $primary-color;
}
.label-info{
    background-color: $info-color;
}
.label-success{
    background-color: $success-color;
}
.label-warning{
    background-color: $warning-color;
}
.label-danger{
    background-color: $danger-color;
}
