@mixin tag-color ($color){
    .tag{
         background-color: $color;
         color: $white-color;
         .tagsinput-remove-link{
             color: $white-color;
         }
     }
     .tagsinput-add{
         color: $color;
     }
}

@mixin create-colored-tags(){
    &.tag-primary{
        @include tag-color($primary-color);
    }
    &.tag-info {
        @include tag-color($info-color);
    }
    &.tag-success{
        @include tag-color($success-color);
    }
    &.tag-warning{
        @include tag-color($warning-color);
    }
    &.tag-danger{
        @include tag-color($danger-color);
    }
}
