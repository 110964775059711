/*     General overwrite     */
body {
  color: $font-color;
  font-size: $font-size-base;
  font-family: 'Muli', Arial, sans-serif;
  .wrapper {
    min-height: 100vh;
    position: relative;
  }
}

a {
  color: $info-color;

  &:hover, &:focus {
    color: $info-states-color;
    text-decoration: none;
  }
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

.ui-slider-handle:focus,
.navbar-toggle,
input:focus,
button:focus {
  outline: 0 !important;
}


/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  @include transition($general-transition-time, $transition-linear);
}


.sidebar .nav a,
.sidebar .nav .collapse i,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .user .info .collapse .nav a > span,
.sidebar .logo a.logo-mini,
.sidebar .logo a.logo-normal,
.table > tbody > tr .td-actions .btn,
.caret{
  @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}

.fa {
  width: 21px;
  text-align: center;
}

.fa-base {
  font-size: 1.25em !important;
}

.margin-top {
  margin-top: 50px;
}

hr {
  border-color: $medium-pale-bg;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.icon-section {
  margin: 0 0 3em;
  clear: both;
  overflow: hidden;
}

.icon-container {
  width: 240px;
  padding: .7em 0;
  float: left;
  position: relative;
  text-align: left;
}

.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #000;
  position: absolute;
  margin-top: 3px;
  transition: .3s;
}

.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}

.icon-container:hover .icon-name {
  color: #000;
}

.icon-name {
  color: #aaa;
  margin-left: 35px;
  font-size: .8em;
  transition: .3s;
}

.icon-container:hover .icon-name {
  margin-left: 45px;
}

.places-buttons .btn {
  margin-bottom: 30px
}

.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.animation-transition-general{
  @include transition($general-transition-time, $transition-linear);
}

.animation-transition-fast{
  @include transition($fast-transition-time, $transition-linear);
}
.animation-transition-ultra-fast{
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}
