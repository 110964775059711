.sidebar,
.off-canvas-sidebar{
  width: 260px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  // overflow: hidden;

  .sidebar-background{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .logo{
    padding: 5px 0px;
    // padding: 15px 0px;
    // padding: 18px 0px;
    margin: 0;
    box-shadow: inset -1px 0px 0px 0px #cfcfca;
    // height: 75px;
    position: relative;
    z-index: 4;
    display: block;

    a.logo-mini{
      float: left;
      width: 30px;
      text-align: center;
      margin-left: 23px;
      margin-right: 15px;
    }

    a.logo-normal{
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }

    .logo-img{
        width: 34px;
        display: inline-block;
        height: 34px;
        margin-left: 0px;
        margin-right: 10px;
        background: white;
        border-radius: 40px;
        text-align: center;

      img{
          max-width: 18px;
          position: relative;
      }
    }

    .simple-text{
      padding: 4px 0px;
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
      line-height: 34px;
      text-align: left;
      white-space: nowrap;
    }
    p{
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      line-height: 20px;
    }

    &:before{
      content: "";
      position: absolute;
      bottom: 0px;
      right: 15px;
      width: calc(100% - 30px);
      height: 1px;
    }
  }

  .sidebar-wrapper{
    position: relative;
    height: calc(100% - 75px);
    // max-height: none;
    // min-height: 100%;
    z-index: 4;
    box-shadow: inset -1px 0px 0px 0px $medium-gray;
    width: 260px;

    .dropdown.open .dropdown-backdrop{
      display: none !important;
    }

    > .nav{
      margin-top: 10px;

      i{
        font-size: 24px;
        float: left;
        margin-right: 15px;
        line-height: 30px;
        width: 30px;
        text-align: center;
      }

      li{
        > a{
          margin: 10px 0px 0px;
          padding-left: 25px;
          padding-right: 25px;
          opacity: .7;
          white-space: nowrap;
        }

        &:hover > a{
          opacity: 1;
        }

        &.active > a{
          color: $primary-color;
          opacity: 1;
        }

        &.separator{
          margin: 15px 0;

          &:after{
            content: "";
            position: absolute;
            height: 1px;
            right: 10%;
            width: 80%;
            background-color: rgba(255,255,255, .3);
          }

          & + li {
            margin-top: 31px;
          }
        }
      }

      p{
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        @include transform-translate-x(0px);
        opacity: 1;
        display: block;
        height: auto;
        white-space: nowrap;
      }

      .caret{
        margin-top: 12px;
        position: absolute;
        right: 1px;
      }

      [data-toggle="collapse"] ~ div > ul > li > a {
        margin: 0;
      }
    }

    > .nav,
    .user .info{
      [data-toggle="collapse"] ~ div > ul > li > a{
        .sidebar-normal{
          margin: 0;
          position: relative;
          @include transform-translate-x(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
        }

        .sidebar-mini{
          text-transform: uppercase;
          float: left;
          width: 30px;
          text-align: center;
          margin-right: 15px;
          letter-spacing: 1px;
        }

        i{
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
  }

  .user{
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;

    &:after{
      content: "";
      position: absolute;
      bottom: 0px;
      right: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: rgba(255,255,255, .3);
    }

    .photo{
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      margin-right: 11px;
      z-index: 5;
      border-radius: 50%;
      margin-left: 23px;

      img{
        width: 100%;
      }
    }

    .info{
      a{
        padding: $padding-base-vertical 25px;
        white-space: nowrap;
        display: block;
        opacity: .7;
        position: relative;

        &:hover,
        &:visited{
          opacity: 1;
        }
      }

      > a > span{
        opacity: 1;
        line-height: 22px;
        display: block;
        position: relative;
      }

      .caret{
        position: absolute;
        top: 11px;
        right: 0px;
      }
    }
  }

  &:after,
  &:before{
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background:  $white-background-color;
  }

  &,
  &[data-background-color="white"]{
    @include sidebar-background-color($white-background-color, $default-color);

    .user{
      margin-top: 20px;

      .photo{
        border: 2px solid rgba(0, 0, 0, 0.15);
      }
    }
  }

  &[data-background-color="brown"]{
    @include sidebar-background-color($brown-background-color, $white-color);
  }

  &[data-background-color="black"] {
    @include sidebar-background-color($black-background-color, $white-color);
  }
  &[data-background-color="darkblue"] {
    @include sidebar-background-color($darkblue-background-color, $white-color);
  }

  &[data-background-color="brown"],
  &[data-background-color="darkblue"],
  &[data-background-color="black"]{
      &[data-active-color="danger"]{
        @include sidebar-active-color( lighten($danger-color, 10%) );
      }

      .info{
        a{
          color: $white-color;
        }
      }

      .user{
        .photo{
          border-color: rgba($white-color, 0.3);
        }
      }

  }
  &[data-active-color="primary"]{
    @include sidebar-active-color($primary-color);
  }
  &[data-active-color="info"]{
    @include sidebar-active-color($info-color);
  }
  &[data-active-color="success"]{
    @include sidebar-active-color($success-color);
  }
  &[data-active-color="warning"]{
    @include sidebar-active-color($warning-color);
  }
  &[data-active-color="danger"]{
    @include sidebar-active-color($danger-color);
  }

}

.main-panel{
  background-color: $bg-nude;
  overflow: auto;
  position: initial;
  // position: relative;
  z-index: 2;
  float: right;
  width: $sidebar-width;
  min-height: 100%;
  overflow-x: hidden;
  > .content{
    padding: 30px 15px 0 15px;
    min-height: calc(100% - 135px);
    // min-height: calc(100% - 120px);
    // min-height: calc(100% - 150px);

    .row .col-md-12 > .title{
      margin-top: 0;
    }
  }

  > .footer{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar{
    margin-bottom: 0;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper{
  -webkit-transition-property: top,bottom,width;
  transition-property: top,bottom, width;
  -webkit-transition-duration: .2s,.2s, .35s;
  transition-duration: .2s,.2s, .35s;
  -webkit-transition-timing-function: linear,linear,ease;
  transition-timing-function: linear,linear,ease;
  -webkit-overflow-scrolling: touch;
}

.sidebar,
.main-panel{
  max-height: 100%;
  height: 100%;
}


// regular settings for perfect scrollbar off
.perfect-scrollbar-off{
  .sidebar .sidebar-wrapper,
  .main-panel{
    overflow: auto;
  }
}

@media (min-width: $screen-md){
  .sidebar-mini{
    .sidebar,
    .sidebar .sidebar-wrapper{
      width: 80px;
    }

    .main-panel{
      width: $sidebar-mini-width;
    }

    .sidebar{
      display: block;
      font-weight: 200;
      z-index: 1000;

      .logo{
        a.logo-mini{
          opacity: 1;
        }

        a.logo-normal{
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper{
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p{
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    // .sidebar:hover{
    //   width: 260px;
    //
    //   .logo{
    //     a.logo-normal{
    //       opacity: 1;
    //       @include transform-translate-x(0px);
    //     }
    //   }
    //
    //   .sidebar-wrapper{
    //     width: 260px;
    //
    //     > .nav li > a p,
    //     > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
    //     .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
    //     .user .info > a > span{
    //       @include transform-translate-x(0px);
    //       opacity: 1;
    //     }
    //
    //     > .nav{
    //       li.active{
    //         > a:not([data-toggle="collapse"]){
    //           &:before,
    //           &:after{
    //             opacity: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
