.el-collapse{
  border:none !important;
}
.el-collapse-item{
    background-color: $pale-bg;
    border-radius: $border-radius-small;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    line-height: 1.6em;
    margin-top: 10px;
    padding: 7px 10px;
    border: 1px solid $gray-input-outline;
}
.el-collapse-item__wrap{
  background-color: $pale-bg;
  border-bottom: none !important;
}
.el-collapse-item{
    .el-collapse-item__header {
        background-color: $pale-bg;
        border: 0 none;
        border-radius: $border-radius-small;
        font-weight: bold;
        font-size: $font-size-medium;
        color: $font-color;
        &:hover,
        &:active,
        &:focus{
          color: $danger-color;
        }
    }
    .el-collapse-item__content{
      background-color: $pale-bg;
      border: none;
      box-shadow: none;
    }
}
