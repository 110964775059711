#nodesContent{

  #schedJobNodeFilter{
    border-top: 1px solid #cfcfca;
    border-bottom: 1px solid #cfcfca;
    // border-left: 1px solid #cfcfca;
  }
  .column-title{
    margin-top:0;
    margin-bottom:0;
  }
  #tagdemo{
    margin-bottom:1em;
    padding-bottom:1em;
    border-bottom:1px solid #b7b7b7;
  }
}
