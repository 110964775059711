.label{
  &.label-default{
    a{
      color: $white-color;
    }
  }
  &.label-white{
    background-color: white;
    color: black;
  }
}
