// .sidebar,
// .main-panel{
//   max-height: initial;
//   height:
//   // max-height: 100%;
//   // height: 100%;
// }

.sidebar{
  .logo{
    background-color: #000000;
    a.home{
      font-family: 'Futura';
      text-transform: uppercase;
      color: #ffffff;
      padding: 15px 15px 15px 32px;
      font-size: 18px;
      line-height: 20px;
      height: 50px;
      background-image: url("logos/rundeck-text.png");
      background-size: 50%;
      background-repeat: no-repeat;
      width: 100%;
      display: block;
      background-position-y: 15px;
      background-position-x: 65px;
      // .appTitle{
      //   padding-left:8px;
      //   opacity: 1;
      //   -webkit-transition: opacity 700ms, visibility 700ms;
      //   transition: opacity 700ms, visibility 700ms;
      // }
    }
    .navbar-minimize{
      position: absolute;
      right: -1em;
      top: 1.8em;
      .triangle{
        cursor: pointer;
        z-index: 1;
        color: white;
        position: absolute;
        left: -2.15em;
        padding: .5em;
        top: -7px;
      }
      // .btn:focus{
      //   background-color: black;
      //   color: #66615B;
      // }
      // sidebar open
      i.fas.fa-ellipsis-v,
      i.fas.fa-sign-out-alt,
      i.fas.fa-chevron-left{
        display: block;
      }
      i.fas.fa-ellipsis-h,
      i.fas.fa-sign-in-alt,
      i.fas.fa-chevron-right{
        display: none;
      }
      // sidebar closed
      .sidebar-mini &{
        i.fas.fa-ellipsis-v,
        i.fas.fa-sign-out-alt,
        i.fas.fa-chevron-left{
          display: none;
        }
        i.fas.fa-ellipsis-h,
        i.fas.fa-sign-in-alt,
        i.fas.fa-chevron-right{
          display: block;
        }
      }
      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 0 17px 17px;
        border-color: transparent transparent transparent #000000;
        content: "";
        position: absolute;
        right: -2px;
        top: -10px;
      }
    }

  }
  .nav{
    margin-bottom: 40px;
  }
  .nav > li.snapshot-version{
    position: absolute;
    width: 100%;
    bottom: 0;
    a{
      background: rgba(255, 255, 255, 0.14);
      opacity: 1;
      color: #FFFFFF;
    }
  }
  .snapshot-version{
    margin-top:20px;
    padding: 10px 0;
    // border-top:1px solid #fa2501;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 258px;
    background: #000;
    // background: #212120;
    a{
      // background: rgba(255, 255, 255, 0.14);
      opacity: 1;
      color: #FFFFFF;
    }
    .badge{
      color: #000;
    }
  }
}

.sidebar-modal-backdrop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}
body.modal-open{
  .sidebar .sidebar-modal-backdrop{
    display: block;
    max-width:260px;
    filter: alpha(opacity=50);
    opacity: .5;
  }
  &.sidebar-mini{
    .sidebar .sidebar-modal-backdrop{
      display: block;
      max-width:80px;
      filter: alpha(opacity=50);
      opacity: .5;
    }
  }
}
.sidebar{
  .sidebar-mini:not(.nav-open) &{
    a.home{
      background-image: none;
    }
    .appTitle{
      opacity: 0;
      display: none;
      // position: absolute;
    }
    .snapshot-version{
      display: none;
    }
    .logo{
      // .navbar-minimize{
      //   // display: inline-block;
      //   position: absolute;
      //   right: -2em;
      //   top: 1em;
      //   // z-index: 4000;
      // }
    }
  }
}
// .sidebar:not(:hover){
//   .sidebar-mini &{
//     a{
//       background-image: none;
//     }
//     .appTitle{
//       opacity: 0;
//       display: none;
//       // position: absolute;
//     }
//     .snapshot-version{
//       display: none;
//     }
//   }
// }

.sidebar{
  .subnav-open{
    .caret{
      border-top: none;
      border-bottom:4px dashed;
    }
  }
}

// .sidebar:hover{
//   .logo{
//     .navbar-minimize{
//       display: inline-block;
//       position: absolute;
//       right: 1em;
//       top: 1em;
//     }
//   }
// }
