@mixin label-style(){
    padding: 3px 8px;
    border-radius: 12px;
    color: $white-color;
    font-weight: 500;
    font-size: 0.75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
}
