$table-border-color: #eeeeee;
//////////////////////////
// execution output table view
table.execoutput {
  border-collapse: separate;

  .stepnum.short:before {
    content: "•";
  }

  td.node {
    color: #898989;
    text-align: right;
    width: 6em;
    max-width: 20em;
    padding: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td.info {
    font-family: courier, mono;
  }

  tr.contextRow.expandable.closed {
    background: $table-border-color;
  }

  tr.contextRow.expandable.opened {
    background: #eeeeee;
  }

  tr.contextRow.down {
    td {
      border-top: 1px solid #dddddd;
      border-bottom: 0;
    }
  }

  tr.contextRow.closed {
    .stepident {
      display: inline;
    }
  }

  td.node.empty {
    color: #eee;
  }

  tr.node-new > td {
    border-top: 2px solid white;
  }

  td.node {}

  td.repeat {
    //      visibility: hidden;
  }

  tr.contextRow.expandable.closed:hover {
    background: #e3edfa;
  }

  tr.contextRow {
    color: #898989;

    td.icon {
      padding: 1px 3px;
    }

    td {
      padding: 5px;
      vertical-align: middle;
    }

    td.rowexpicon {
      width: 16px;
      margin: 0;
      padding: 4px;
      vertical-align: middle;
    }

    span.contextInfo {
      color: #555;
    }

    span.node {
      font-weight: bold;
    }
  }

  td {
    border: 1px solid $table-border-color;
    vertical-align: baseline;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  td.node.empty:before {
    content: 'console';
  }

  tr.contextRow.console {
    background: #fff;

    td {
      color: #555;
    }
  }

  .ctxcounter {
    margin-left: 10px;
    font-family: monospace;
  }

  td.data.datahtml {
    //      font-family: verdana, arial, helvetica, sans-serif;

    a {
      text-decoration: underline;
      color: #898989;
    }

    code {
      font-family: "Lucida Console", courier, monospace;
    }
  }

  tr.alternateRow {
    td.info.time {
      color: #808080;
    }

    td.data {
      background: #f0f0f0;
    }
  }

  tr.contextRow.up {
    td {
      border-top: 0;
    }
  }

  td.stepnum {
    padding: 0 0.5em;
    width: 5em;
    white-space: nowrap;
  }

  td.info.time {
    color: #898989;
    white-space: nowrap;
    width: 6em;
  }

  th {
    background: none;
    border: none;
  }

  td.datahtml {
    white-space: normal;

    p {
      margin: 0;
    }

    p+p {
      margin: 1em 0 0;
    }
  }

  td.data {
    border-left: 1px solid white;
    white-space: pre-wrap;
    font-family: "Lucida Console", courier, monospace;
    padding: 1px 3px;
    color: black;
  }

  &.no-wrap {
    td.data {
      white-space: pre;
    }
  }

  td.time {
    padding: 0 3px;
    background-color: #eeeeee;
  }

  td.node,
  td.stepnum {
    padding: 0 3px;
    background-color: #eeeeee;
  }

  td.data.log_debug,
  td.data.log_verbose {
    color: #898989;
  }

  span.node:after,
  td.node:after {}

  table.execoutput.collapse_node {
    td.node {
      display: none;
    }
  }

  td.data.log_error {
    color: #800;
  }

  td.data.log_warn {
    color: #a80;
  }

  table.execoutput.collapse_stepnum {
    td.stepnum {
      display: none;
    }
  }

  table.execoutput.collapse_time td.info,
  table.execoutput.collapse_time td.time {
    display: none;
  }

  .ctxcounter.ERROR,
  .ctxcounter.SEVERE {
    color: red;
  }

  &.collapse_time td.info,
  &.collapse_time td.time {
    display: none;
  }

  &.collapse_node td.node {
    display: none;
  }

  &.collapse_stepnum td.stepnum {
    display: none;
  }
}
