.card.card-modified{
  &.card-grey-header{
    .card-header{
      background-color: #e8e8e8;
      border-radius: 6px 6px 0 0;
      // color: #fff;
      padding-bottom: 15px;
      a{
        // color: #fff;
      }
    }
  }
}
